import foto_bbb from '../images/FOTO_BBB.png'
import foto_apas from '../images/FOTO_APAS.jpeg'
import foto_seara from '../images/FOTO_SEARA.png'
import foto_maisvoce from '../images/FOTO_MAISVOCE.png'
import foto_nossosonho from '../images/FOTO_NOSSOSONHO.png'
import foto_naotemvolta from '../images/FOTO_NAOTEMVOLTA.jpeg'
import foto_poliana from '../images/FOTO_POLIANA.jpg'
import foto_searatop3 from '../images/FOTO_SEARA_TOP3.jpg'
import foto_cni from '../images/FOTO_CNI.jpg'
import foto_tiktok from '../images/FOTO_TIKTOK.jpg'


import foto_identidade from '../images/FOTO_IDENTIDADE.jpg'
import foto_trilha from '../images/FOTO_TRILHA.png'
import foto_loc from '../images/FOTO_LOC.jpeg'
import foto_atendimento from '../images/FOTO_ATENDIMENTO.jpg'

const sc_width = window.innerWidth
  let sc_height = window.innerHeight
  if(sc_height>1200){
    sc_height=1200
  }

async function get_size(source:any){
  const ratio = new Promise(resolve=>{
    let img = new Image()
    img.src = source
    img.onload = function(){
      let rt = img.height/img.width
      resolve(rt)
    }
  })
    return(ratio)
}

export async function get_size_soluc(source:any){
  let img = new Image()
  img.src = source
  let ratio = 0
  img.onload = function(){
      ratio = img.width/img.height
  }
  return(ratio)
}

export const produtos =
    [
      {nome:'TikTok Awards',
        foto: foto_tiktok,
        height: 1992,
        width: 2964,
        link: '1041820760'
      },
      {nome:'Confederação Nacional da Indústria',
        foto: foto_cni,
        height: 2160,
        width: 3840,
        link: '1007102263'
      },
      {nome:'Big Brother Brasil',
        foto: foto_bbb,
        height: 2160,
        width: 3840,
        link: '950035508'
      },
      {nome:'Fiesta Seara SXSW',
        foto: foto_seara,
        height: 2000,
        width: 3200,
        link: '950027053'
      },
      {nome:'APAS Show',
        foto: foto_apas,
        height: 1600,
        width: 2400,
        link: '950027119'
      },
      {nome:'Seara TOP 3',
        foto: foto_searatop3,
        height: 1631, 
        width: 2800,
        link: '980940869'
      },
      {nome:'Nosso Sonho',
        foto: foto_nossosonho,
        width: 2000,
        height: 1324,
        link: '950028312'
      },
      {nome:'Mais Você',
        foto: foto_maisvoce,
        width: 3840,
        height: 2160,
        link: '950039799'
      },
      {nome:'As Aventuras de Poliana',
        foto: foto_poliana,
        width:3584,
        height:2016,
        link: '950031661'
      },
      {nome:'Não Tem Volta',
        foto: foto_naotemvolta,
        width:2400,
        height:1350,
        link: '950031696'
      }
    ]

export const soluc = [
  {nome:'Criação de Identidade Sonora',
    desc:'Conectando marcas com seu público de forma impactante através de uma experiência sonora única.',
    foto:foto_identidade,
    width:2380,
    height:1435
  },
  {nome:'Composição de Trilhas Originais',
    desc:'Associar emoções entre imagem e som é uma arte. Fortalecer a mensagem transmitida é nosso maior objetivo.',
    foto: foto_trilha,
    width:1456,
    height:816,
  },
  {nome:'Locução e Sonorização de Clipes',
    desc:'Fortaleça a comunicação de seus textos em comerciais, palestras ou informativos.',
    foto: foto_loc,
    width: 1470,
    height: 980,
  },
  {nome:'Atendimento Personalizado',
    desc: 'Time dedicado para se adaptar às necessidades da sua equipe.',
    foto: foto_atendimento,
    width: 1120,
    height: 720,
  }
]